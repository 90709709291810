import { graphql } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import styled from "styled-components";

import { Layout, Pagination, PostCard } from "../components/common";
import { MetaData } from "../components/common/meta";

const TagHeader = styled.header`
    max-width: 690px;
    margin: 0 0 4vw;

    h1 {
        margin: 0 0 1rem 0;
    }

    p {
        margin: 0;
        color: var(--color-secondary);
        font-size: 2.2rem;
        line-height: 1.3em;
    }

    @media (max-width: 500px) {
        border-bottom: var(--color-bg) 1px solid;
        padding-bottom: 4vw;
        p {
            font-size: 1.7rem;
        }
    }
`;

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 * @param root0
 * @param root0.data
 * @param root0.location
 * @param root0.pageContext
 */
const Tag = ({ data, location, pageContext }) => {
    const tag = data.ghostTag;
    const posts = data.allGhostPost.edges;

    return (
        <>
            <MetaData data={data} location={location} type="series" />
            <Layout>
                <div className="container">
                    <TagHeader>
                        <h1>{tag.name}</h1>
                        {tag.description ? <p>{tag.description}</p> : null}
                    </TagHeader>
                    <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCard key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    );
};

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Tag;

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
